import Vue from 'vue' //引入vue
import App from './App.vue' //引入根组件
import router from './router' //引入路由
import store from './store' //引入vuex
import './router/permission' //路由守卫权限管理


import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI); //使用elementUI

import './plugins/element.js' //引入elementUI
/*
import '@/assets/scss/variables.scss'
import '@/assets/element-variables.scss'
*/
import 'normalize.css' //引入初始化样式

import '../src/assets/element-variables.scss' //Element UI 改变主题色

import './assets/css/base.scss' //引入全局样式

import './assets/bianbanAdmin.scss' //引入编办相关全局样式

//简约给力的Canvas环形进度条组件
import VueAwesomeProgress from "vue-awesome-progress"
Vue.use(VueAwesomeProgress)


import httpServer from "@/api/index"
Vue.prototype.$httpServer = httpServer //挂载到全局  在组件中通过this.$httpServer 调用

import * as echarts from 'echarts'
Vue.prototype.$echarts = echarts //挂载到全局 在组件中通过this.$echarts 调用

import echartsGL from 'echarts-gl' // 引入echarts
Vue.prototype.$echartsGL = echartsGL // 引入组件（将echarts注册为全局）




import MyBreadcrumb from './components/breadcrumb/index.js' //引入自定义组件
Vue.use(MyBreadcrumb)  // 挂载自定义组件




import moment from 'moment'
Vue.prototype.$moment = moment //挂载到全局 在组件中通过this.$moment 调用

Vue.config.productionTip = false //关闭生产提示

new Vue({
  router,
  store,
  render: h => h(App) //渲染根组件
}).$mount('#app') //挂载到index页面的div上
