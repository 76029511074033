import Vue from 'vue'
import Vuex from 'vuex'

import state from '@/store/defaultState'
import mutations from "@/store/mutations";

import mod1 from "@/store/module/mod1";


Vue.use(Vuex)

export default new Vuex.Store({
  state: state,
  getters: {},
  mutations: mutations,
  actions: {},
  modules: {
    mod1: mod1,
  }
})
