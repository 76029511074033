/*
Breadcrumb 面包屑
根据当前路由信息自动生成
*/

<template>
	<div>
		<el-breadcrumb separator="/">
			<el-breadcrumb-item v-for="item in breadcrumbList" :key="item.name" :to="item.path!=activePath ? { path: item.path===''?'/':item.path }:''">{{ item.meta.title }}</el-breadcrumb-item>
		</el-breadcrumb>
	</div>
</template>

<script>
export default {
	// eslint-disable-next-line vue/multi-word-component-names
	name: "breadcrumb",
	props: {},
	created() {
		//console.log(this.$route)
		//console.log(this.$route.matched)
	},
	computed: {
		activePath(){
			return this.$route.path;
		},
		breadcrumbList() {
			return this.$route.matched;
		}
	},
}
</script>

<style scoped>

</style>