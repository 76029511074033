/**
 * 测试 Module 模块 MOD1
 */

// State
const state = {
	modName: 'MOD1...',
}

// Getter 计算
const getters = {
	getMyName: (state) => {
		return "我的名子是"+state.modName;
	}
}

// Mutation 事件方法 用来改变数据
const mutations = {
	setText (state,data) {
		console.log('Mutations: 执行了setText...',data);
		state.modName = data;
	},
	setName (state,data){
		console.log('Mutations: 执行了setName...',data);
		state.modName = data;
	}
}

// Action 提交的是 mutation，而不是直接变更状态
const actions = {
	setText (context, data) {
		console.log('Action: 执行了setText...',data);
		context.commit('setText',data)
	}
}


export default {
	namespaced: true, // 开启命名空间 ()
	state,
	getters,
	mutations,
	actions
}
