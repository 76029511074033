export function set_token(key, data) {
  return localStorage.setItem(key, data);
}

export function get_token(key) {
  return localStorage.getItem(key);
}

export function remove_token(key) {
  return localStorage.removeItem(key);
}
