<template>
	<div>
		<el-container class="layout">
			<el-header class="layout-header" style="display: flex; align-items: center; justify-content: space-between;">
				<div style="display: flex; align-items: center;">
					<el-button @click="toggleCollapse" type="text" icon="el-icon-s-promotion" style="font-size: 24px; margin-right: 10px;color: #FFFFFF;"></el-button>
					<h2 style="text-transform: capitalize;">{{sysName}} <small style="font-weight: lighter; font-size: 12px;">{{sysVersions}}</small></h2>
				</div>
				<div style="display: flex; align-items: center;">
					<div style="display: flex; align-items: center; margin-right: 10px;">
						<div style="margin-right: 5px;">
							<el-popover
									placement="bottom"
									trigger="hover"
							>
								<el-form size="mini" :inline="true" class="">
									<el-form-item style="margin-bottom: 0;">
										<el-input placeholder="要搜索的关键字"></el-input>
									</el-form-item>
									<el-form-item style="margin-bottom: 0;">
										<el-button type="primary">搜索</el-button>
									</el-form-item>
								</el-form>
								<el-button slot="reference" type="text" icon="el-icon-search" style="color: #FFFFFF; font-size: 18px;"></el-button>
							</el-popover>
						</div>
						<div style="margin-right: 5px;">
							<el-popover
							placement="bottom"
							width="300"
							trigger="hover"
							>
								<el-empty :image-size="100" description="暂无消息"></el-empty>
								<el-button slot="reference" type="text" icon="el-icon-bell" style="color: #FFFFFF; font-size: 18px;"></el-button>
							</el-popover>
						</div>
					</div>
					<el-dropdown size="small">
						<span class="el-dropdown-link" style="display: flex; align-items: center; color: #FFFFFF;">
							<el-avatar size="small" src="https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png" style="margin-right: 10px; outline: 3px solid rgba(255, 255, 255, 0.1);"></el-avatar>
							<div style="display: flex; flex-direction: column; margin-right: 5px;">
								<span style="margin-bottom: 0px; font-size: 14px;">Administrators</span>
							</div>
							<i class="el-icon-arrow-down el-icon--right"></i>
						</span>
						<el-dropdown-menu slot="dropdown">
							<el-dropdown-item>个人中心</el-dropdown-item>
							<el-dropdown-item>设置</el-dropdown-item>
							<el-dropdown-item divided @click.native="logout">退出</el-dropdown-item>
						</el-dropdown-menu>
					</el-dropdown>
				</div>
			</el-header>
			<el-container class="layout-container">
				<el-aside class="layout-aside">
					<v-menu :isCollapse="isCollapse"></v-menu>
				</el-aside>
				<el-main class="layout-main" ref="scrollDiv">
					<!--<v-tagNav></v-tagNav>-->
					<!--<keep-alive></keep-alive>-->
					<router-view @pageScrollToX="pageScrollToX"></router-view>
				</el-main>
			</el-container>
		</el-container>
	</div>
</template>

<script>
import MenuX from './menu/menu.vue'
import TagNav from "./tagnav/tagnav.vue";
import { remove_token } from '@/utils/auth';
export default {
	components: {
		"v-menu": MenuX,
		"v-tagNav": TagNav
	},
	data() {
		return {
			// 系统名称信息
			sysName: "智能客服大数据分析平台",//大数据分析平台
			sysVersions: "V1.0",
			topShowDate: this.$moment().format("YYYY-MM-DD HH:mm:ss"),
			isCollapse: false //控制菜单栏展开收起
		}
	},
	computed:{
	
	},
	mounted() {
		let that = this;
		this.setIntervalTimer = setInterval(function (){
			that.topShowDate = that.$moment().format("YYYY-MM-DD HH:mm:ss");
		})
		// this.pageScrollTo(400);
	},
	methods: {
		toggleCollapse() {
			console.log('切换侧边栏...');
			this.isCollapse = !this.isCollapse
		},
		logout() {
			console.log('退出登录');
			this.$httpServer.logout().then((response) => {
				if (response.code == 0) {
					this.$message.success("退出成功");
					this.$router.push('/login');
					remove_token('token')
				} else {
					
					this.$message.error(response.description);
				}
			})

			// 清除token
			// this.$store.commit('LOGIN_OUT');

		},
		pageScrollToX(num){
			console.log("bb:",num)
			
			//this.$refs.scrollDiv; //scrollElem.scrollHeight
			let scrollElem = document.getElementsByClassName('layout-main')[0];
			console.log(scrollElem);
			this.$nextTick(() => {
				scrollElem.scrollTo({ top: 300, behavior: 'smooth' });
			});
			
		}
	},
	beforeDestroy() {
		if(this.setIntervalTimer){
			clearInterval(this.setIntervalTimer);
		}
	}
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";

.layout {
	height: 100vh;
	display: flex;
}

.layout-header {
	position: relative;
	z-index: 2;
	padding: 15px;
	background-color: $primary-color;
	color: #FFFFFF;
	box-shadow: 3px 3px 10px rgba(0,0,0,0.3);
}

.layout-container {
	position: relative;
	z-index: 1;
	flex-grow: 1;
	display: flex;
}

.layout-aside {
	width: auto !important;
	background-color: #3C3F41;
	flex-shrink: 0;
	height: calc(100vh - 60px);
}

.layout-main {
	padding: 0;
	flex-grow: 1;
	height: calc(100vh - 60px);
	overflow-x: hidden;
}
</style>