import Vue from 'vue'
import VueRouter from 'vue-router'

import Layout from '../views/layout/index.vue'

Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		component: Layout,
		redirect: '/home',
		meta: {
			title: '首页',
			requiresAuth: true
		},
		children: [
			{
				path: 'home',
				name: 'home',
				component: () => import('@/views/home/home.vue'),
				meta: { title: '工作台', icon: 'el-icon-monitor' },
			},
			{
				path: 'data_statistics',
				name: 'data_statistics',
				component: () => import('@/views/layout/no_page.vue'),
				redirect: '/data_statistics/hot_word_statistics/hot_word_setting',
				meta: { title: '数据统计', icon: 'el-icon-set-up' },
				children: [
					{
						path: 'hot_word_statistics',
						name: 'hot_word_statistics',
						component: () => import('@/views/layout/no_page.vue'),
						redirect: '/data_statistics/hot_word_statistics/hot_word_setting',
						meta: { title: '热词统计', icon: 'el-icon-files' },
						children: [
							//热词统计
							{
								path: 'hot_word_setting',
								name: 'hot_word_setting',
								component: () => import('@/views/data_statistics/hot_word_statistics/hot_word_setting.vue'),
								meta: { title: '热词设置', icon: 'el-icon-files' },
							},
							{
								path: 'data_query',
								name: 'data_query',
								component: () => import('@/views/data_statistics/hot_word_statistics/data_query.vue'),
								meta: { title: '数据查询', icon: 'el-icon-files' },
							},
							{
								path: 'data_reverse_query',
								name: 'data_reverse_query',
								component: () => import('@/views/data_statistics/hot_word_statistics/data_reverse_query.vue'),
								meta: { title: '数据反查', icon: 'el-icon-files' },
							}
						]
					},
					{
						path: 'sensitive_word_statistics',
						name: 'sensitive_word_statistics',
						component: () => import('@/views/layout/no_page.vue'),
						redirect: '/data_statistics/sensitive_word_statistics/sensitive_word_setting',
						meta: { title: '敏感词统计', icon: 'el-icon-files' },
						children: [
							//敏感词统计
							{
								path: 'sensitive_word_setting',
								name: 'sensitive_word_setting',
								component: () => import('@/views/data_statistics/sensitive_word_statistics/sensitive_word_setting.vue'),
								meta: { title: '敏感词设置', icon: 'el-icon-files' },
							},
							{
								path: 'data_query',
								name: 'data_query',
								component: () => import('@/views/data_statistics/sensitive_word_statistics/data_query.vue'),
								meta: { title: '数据查询', icon: 'el-icon-files' },
							},
							{
								path: 'data_reverse_query',
								name: 'data_reverse_query',
								component: () => import('@/views/data_statistics/sensitive_word_statistics/data_reverse_query.vue'),
								meta: { title: '数据反查', icon: 'el-icon-files' },
							},
						]
					},
					{
						path: 'work_order_statement',
						name: 'work_order_statement',
						component: () => import('@/views/layout/no_page.vue'),
						redirect: '/data_statistics/work_order_statement/business_category_statistics',
						meta: { title: '工单报表', icon: 'el-icon-files' },
						children: [
							//工单报表
							{
								path: 'business_category_statistics',
								name: 'business_category_statistics',
								component: () => import('@/views/data_statistics/work_order_statement/business_category_statistics.vue'),
								meta: { title: '业务类别统计', icon: 'el-icon-files' },
							},
							{
								path: 'event_type_statistics',
								name: 'event_type_statistics',
								component: () => import('@/views/data_statistics/work_order_statement/event_type_statistics.vue'),
								meta: { title: '事件类型统计', icon: 'el-icon-files' },
							},
							{
								path: 'source_channel_statistics',
								name: 'source_channel_statistics',
								component: () => import('@/views/data_statistics/work_order_statement/source_channel_statistics.vue'),
								meta: { title: '来源渠道统计', icon: 'el-icon-files' },
							},
							{
								path: 'range_statistics',
								name: 'range_statistics',
								component: () => import('@/views/data_statistics/work_order_statement/range_statistics.vue'),
								meta: { title: '区域统计', icon: 'el-icon-files' },
							},
							{
								path: 'department_workload_statistics',
								name: 'department_workload_statistics',
								component: () => import('@/views/data_statistics/work_order_statement/department_workload_statistics.vue'),
								meta: { title: '部门工作量统计', icon: 'el-icon-files' },
							},
							{
								path: 'agent_workload_statistics',
								name: 'agent_workload_statistics',
								component: () => import('@/views/data_statistics/work_order_statement/agent_workload_statistics.vue'),
								meta: { title: '坐席工作量统计', icon: 'el-icon-files' },
							},
						]
					},
					{
						path: 'telephone_traffic_statement',
						name: 'telephone_traffic_statement',
						component: () => import('@/views/layout/no_page.vue'),
						redirect: '/data_statistics/telephone_traffic_statement/call_capture_rate_statistics',
						meta: { title: '话务报表', icon: 'el-icon-files' },
						children: [
							//话务报表
							{
								path: 'call_capture_rate_statistics',
								name: 'call_capture_rate_statistics',
								component: () => import('@/views/data_statistics/telephone_traffic_statement/call_capture_rate_statistics.vue'),
								meta: { title: '接通率统计', icon: 'el-icon-files' },
							},
							{
								path: 'outbound_call_statistics',
								name: 'outbound_call_statistics',
								component: () => import('@/views/data_statistics/telephone_traffic_statement/outbound_call_statistics.vue'),
								meta: { title: '外呼统计', icon: 'el-icon-files' },
							},
							{
								path: 'ivr_statistics',
								name: 'ivr_statistics',
								component: () => import('@/views/data_statistics/telephone_traffic_statement/ivr_statistics.vue'),
								meta: { title: 'IVR统计', icon: 'el-icon-files' },
							},
						]
					},

				]
			},





			{
				path: 'data_analysis',
				name: 'data_analysis',
				component: () => import('@/views/layout/no_page.vue'),
				redirect: '',
				meta: { title: '数据分析', icon: 'el-icon-coin' },
				children: [
					//数据分析
					{
						path: 'repeated_complaint',
						name: 'repeated_complaint',
						component: () => import('@/views/layout/no_page.vue'),
						redirect: '',
						meta: { title: '重复投诉', icon: 'el-icon-files' },
						children: [
							{
								path: 'duplicate_calling_number',
								name: 'duplicate_calling_number',
								component: () => import('@/views/data_analysis/repeated_complaint/duplicate_calling_number.vue'),
								meta: { title: '来电号码重复', icon: 'el-icon-files' },

							},
							{
								path: 'call_appeal_repeat',
								name: 'call_appeal_repeat',
								component: () => import('@/views/data_analysis/repeated_complaint/call_appeal_repeat.vue'),
								meta: { title: '来电诉求重复', icon: 'el-icon-files' },
							},
							{
								path: 'appeal_repeat',
								name: 'appeal_repeat',
								component: () => import('@/views/data_analysis/repeated_complaint/appeal_repeat.vue'),
								meta: { title: '诉求重复', icon: 'el-icon-files' },
							},
						]
					},
					{
						path: 'work_order_analysis',
						name: 'work_order_analysis',
						component: () => import('@/views/layout/no_page.vue'),
						redirect: '',
						meta: { title: '工单分析', icon: 'el-icon-files' },
						children: [
							{
								path: 'returned_analysis',
								name: 'returned_analysis',
								component: () => import('@/views/data_analysis/work_order_analysis/returned_analysis.vue'),
								meta: { title: '退回分析', icon: 'el-icon-files' },

							},
							{
								path: 'postpone_analysis',
								name: 'postpone_analysis',
								component: () => import('@/views/data_analysis/work_order_analysis/postpone_analysis.vue'),
								meta: { title: '延期分析', icon: 'el-icon-files' },
							},
							{
								path: 're_register_analysis',
								name: 're_register_analysis',
								component: () => import('@/views/data_analysis/work_order_analysis/re_register_analysis.vue'),
								meta: { title: '重办分析', icon: 'el-icon-files' },
							},
						]
					},
					{
						path: 'transact_dissatisfaction_analysis',
						name: 'transact_dissatisfaction_analysis',
						component: () => import('@/views/layout/no_page.vue'),
						redirect: '',
						meta: { title: '办理不满意分析', icon: 'el-icon-files' },
						children: [
							{
								path: 'incoming_call_dissatisfaction_analysis',
								name: 'incoming_call_dissatisfaction_analysis',
								component: () => import('@/views/data_analysis/transact_dissatisfaction_analysis/incoming_call_dissatisfaction_analysis.vue'),
								meta: { title: '来电不满意分析', icon: 'el-icon-files' },
							},
							{
								path: 'business_category_dissatisfaction_analysis',
								name: 'business_category_dissatisfaction_analysis',
								component: () => import('@/views/data_analysis/transact_dissatisfaction_analysis/business_category_dissatisfaction_analysis.vue'),
								meta: { title: '业务类别不满意分析', icon: 'el-icon-files' },
							},
							{
								path: 'event_type_dissatisfaction_analysis',
								name: 'event_type_dissatisfaction_analysis',
								component: () => import('@/views/data_analysis/transact_dissatisfaction_analysis/event_type_dissatisfaction_analysis.vue'),
								meta: { title: '事件类型不满意分析', icon: 'el-icon-files' },
							},
						]
					},
					{
						path: 'three_related_analysis',
						name: 'three_related_analysis',
						component: () => import('@/views/layout/no_page.vue'),
						redirect: '',
						meta: { title: '三涉分析', icon: 'el-icon-files' },
						children: [
							{
								path: 'involve_multiplayer_analysis',
								name: 'involve_multiplayer_analysis',
								component: () => import('@/views/data_analysis/three_related_analysis/involve_multiplayer_analysis.vue'),
								meta: { title: '涉众分析', icon: 'el-icon-files' },

							},
							{
								path: 'involve_petition_analysis',
								name: 'involve_petition_analysis',
								component: () => import('@/views/data_analysis/three_related_analysis/involve_petition_analysis.vue'),
								meta: { title: '涉企分析', icon: 'el-icon-files' },
							},
							{
								path: 'involve_stable_analysis',
								name: 'involve_stable_analysis',
								component: () => import('@/views/data_analysis/three_related_analysis/involve_stable_analysis.vue'),
								meta: { title: '涉稳分析', icon: 'el-icon-files' },
							},
						]
					},
					{
						path: 'hot_word_analysis',
						name: 'hot_word_analysis',
						component: () => import('@/views/data_analysis/hot_word_analysis/hot_word_analysis.vue'),
						meta: { title: '热词分析', icon: 'el-icon-files' },
					},
				]
			},





			{
				path: 'portrait',
				name: 'portrait',
				component: () => import('@/views/layout/no_page.vue'),
				redirect: '/portrait/individual_portrait',
				meta: { title: '画像', icon: 'el-icon-set-up' },
				children: [
					{
						path: 'individual_portrait',
						name: 'individual_portrait',
						component: () => import('@/views/portrait/individual_portrait.vue'),
						meta: { title: '个人画像', icon: 'el-icon-user' },

					},
					{
						path: 'companies_portrait',
						name: 'companies_portrait',
						component: () => import('@/views/portrait/companies_portrait.vue'),
						meta: { title: '企业画像', icon: 'el-icon-office-building' },
					},
					{
						path: 'region_portrait',
						name: 'region_portrait',
						component: () => import('@/views/portrait/region_portrait.vue'),
						meta: { title: '区域画像', icon: 'el-icon-location-information' },
					},
					{
						path: 'portrait_show',
						name: 'portrait_show',
						component: () => import('@/views/portrait/portrait_show.vue'),
						meta: { title: '画像展示', icon: 'el-icon-data-board' },
					},

				]
			},


			{
				path: 'big_screen',
				name: 'big_screen',
				component: () => import('@/views/layout/no_page.vue'),
				redirect: '/big_screen_index',
				meta: { title: '数据大屏', icon: 'el-icon-set-up' },
				children: [
					{
						path: 'big_screen0',
						name: 'big_screen0',
						component: () => import('@/views/layout/no_page.vue'),
						redirect: '/big_screen_index0',
						meta: { title: '12345系统总览', icon: 'el-icon-user', isBlank: true },

					},
					{
						path: 'big_screen1',
						name: 'big_screen1',
						component: () => import('@/views/layout/no_page.vue'),
						redirect: '/big_screen_index',
						meta: { title: '12345热线运营态势', icon: 'el-icon-user', isBlank: true },

					},
					{
						path: 'big_screen2',
						name: 'big_screen2',
						component: () => import('@/views/layout/no_page.vue'),
						redirect: '/big_screen_index2',
						meta: { title: '12345热线专题分析', icon: 'el-icon-office-building', isBlank: true },
					},
					{
						path: 'big_screen3',
						name: 'big_screen3',
						component: () => import('@/views/layout/no_page.vue'),
						redirect: '/big_screen_index3',
						meta: { title: '12345热线专题', icon: 'el-icon-user', isBlank: false },

					},
					{//养老情况分析
						path: '/yanglaofenxi',
						name: 'yanglaofenxi',
						component: () => import('@/views/layout/no_page.vue'),
						redirect: '/big_screen_index_yanglaofenxi',
						meta: { title: '养老情况分析', icon: 'el-icon-user',isBlank:true },
					},


				]
			},

			{
				path: 'system_management',
				name: 'system_management',
				component: () => import('@/views/layout/no_page.vue'),
				redirect: '/system_management/user_management',
				meta: { title: '系统管理', icon: 'el-icon-setting' },
				children: [
					{
						path: 'user_management',
						name: 'user_management',
						component: () => import('@/views/system_management/user_management.vue'),
						meta: { title: '用户管理', icon: 'el-icon-user' },

					},
					{
						path: 'role_management',
						name: 'role_management',
						component: () => import('@/views/system_management/role_management.vue'),
						meta: { title: '角色管理', icon: 'el-icon-news' },
					},
					{
						path: 'menu_management',
						name: 'menu_management',
						component: () => import('@/views/system_management/menu_management.vue'),
						meta: { title: '菜单管理', icon: 'el-icon-notebook-2' },
					},
					{
						path: 'tenant_management',
						name: 'tenant_management',
						component: () => import('@/views/system_management/tenant_management.vue'),
						meta: { title: '租户管理', icon: 'el-icon-office-building' },
					},
				]
			},

			{
				path: 'work_order_details',
				name: 'work_order_details',
				component: () => import('@/views/work_order_details/work_order_details.vue'),
				meta: { title: '工单详情', icon: 'el-icon-user' },
			},



		]
	},
	{
		path: '/login',
		name: 'Login',
		component: () => import('@/views/login/index.vue'),
		meta: {
			title: '登录',
		},
	},

	{
		path: '/big_screen_index0',
		name: 'big_screen_index0',
		component: () => import('@/views/bigDataScreen/index0.vue'),
		meta: { title: '12345系统总览', icon: 'el-icon-user', target: '_blank' },
	},
	{
		path: '/big_screen_index',
		name: 'big_screen_index',
		component: () => import('@/views/bigDataScreen/index.vue'),
		meta: { title: '12345运营态势', icon: 'el-icon-user', target: '_blank' },
	},
	{
		path: '/big_screen_index2',
		name: 'big_screen_index2',
		component: () => import('@/views/bigDataScreen/index2.vue'),
		meta: { title: '12345专题分析', icon: 'el-icon-user', target: '_blank' },
	},
	{
		path: '/big_screen_index3',
		name: 'big_screen_index3',
		component: () => import('@/views/bigDataScreen/index3.vue'),
		meta: { title: '12345数据分析', icon: 'el-icon-user', target: '_blank' },
	},

	{//315专题二级页面
		path: '/big_screen_index_sub1',
		name: 'big_screen_index_sub1',
		component: () => import('@/views/bigDataScreen/index_sub1.vue'),
		meta: { title: '12315专题分析', icon: 'el-icon-user', target: '_blank' },
	},
	{//话务的二级页面
		path: '/big_screen_index_sub2',
		name: 'big_screen_index_sub2',
		component: () => import('@/views/bigDataScreen/index_sub2.vue'),
		meta: { title: '12345话务分析', icon: 'el-icon-user', target: '_blank' },
	},

	{//养老情况分析
		path: '/big_screen_index_yanglaofenxi',
		name: 'big_screen_index_yanglaofenxi',
		component: () => import('@/views/bigDataScreen/index_yanglaofenxi.vue'),
		meta: { title: '养老情况分析', icon: 'el-icon-user', target: '_blank' },
	},



	{
		path: '/bianbanAdmin',
		name: 'bianbanAdmin',
		component: () => import('@/views/bianbanAdmin/index.vue')
	},

	{
		path: '/404',
		name: '404',
		component: () => import('@/views/error/404.vue')
	},
	{
		path: '/403',
		name: '403',
		component: () => import('@/views/error/403.vue')
	},


	{
		path: '/309603339',
		name: '309603339',
		component: () => import('@/views/login/index.vue'),
		beforeEnter: (to, from, next) => { //路由级别的守卫
			next();
		}
	}
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})

// 路由守卫
// 在路由页面间切换跳转时进行一些限制
// beforeEach 全局前置守卫
/*
to: 即将要进入的目标 路由对象
from: 当前导航正要离开的路由
next: 是一个函数，必须调用它来 resolve 这个钩子可以传递一个回调函数，在回调函数中，使用 next 函数来 resolve 这个钩子

*/

/*
router.beforeEach((to, from, next) =>{
	// next(false); // 阻止路由跳转
	// next(true); // 允许路由跳转
	// next(); // 允许路由跳转，并跳转到指定路由
	next(true)
})
*/

// afterEach 全局后置守卫


router.afterEach((to, from, next) => {
	if (to.meta.title) {
		document.title = to.meta.title;
	} else {
		document.title = "Header 布局界面"
	}
	next
})



export default router
