<template>
<!--router-->
<!--
			background-color="#545c64"
			text-color="#fff"
			active-text-color="#ffd04b"
			
			/*padding: 0; margin: 10px; border-radius: 10px; overflow:hidden; border: 5px solid rgba(255,255,255,0.05);*/
-->
	<div style=" ">
	
<!--
		<el-menu
			router
			:default-active="active"
			class="el-menu-vertical-demo"
			:collapse="isCollapse"
			:unique-opened="true"
			:collapse-transition="false"
		>
			<el-menu-item index="/">
				<i class="el-icon-menu"></i><span slot="title">工作台</span>
			</el-menu-item>
			<el-submenu index="/data_statistics">
				<template slot="title"><i class="el-icon-goods"></i><span>数据统计</span></template>
				<el-submenu index="/data_statistics/hot_word_statistics">
					<template slot="title"><i class="el-icon-document"></i><span>热词统计</span></template>
					<el-menu-item index="/data_statistics/hot_word_statistics/hot_word_setting">热词设置</el-menu-item>
					<el-menu-item index="/data_statistics/hot_word_statistics/data_query">数据查询</el-menu-item>
				</el-submenu>
				<el-submenu index="/data_statistics/sensitive_word_statistics">
					<template slot="title"><i class="el-icon-document"></i><span>敏感词统计</span></template>
					<el-menu-item index="/data_statistics/sensitive_word_statistics/sensitive_word_setting">敏感词设置</el-menu-item>
					<el-menu-item index="/data_statistics/sensitive_word_statistics/data_query">数据查询</el-menu-item>
				</el-submenu>
				<el-submenu index="/data_statistics/work_order_statement">
					<template slot="title"><i class="el-icon-document"></i><span>工单报表</span></template>
					<el-menu-item index="/data_statistics/work_order_statement/business_category_statistics">业务类别统计</el-menu-item>
					<el-menu-item index="/data_statistics/work_order_statement/event_type_statistics">事件类型统计</el-menu-item>
					<el-menu-item index="/data_statistics/work_order_statement/source_channel_statistics">来源渠道统计</el-menu-item>
					<el-menu-item index="/data_statistics/work_order_statement/range_statistics">区域统计</el-menu-item>
					<el-menu-item index="/data_statistics/work_order_statement/department_workload_statistics">部门工作量统计</el-menu-item>
					<el-menu-item index="/data_statistics/work_order_statement/agent_workload_statistics">坐席工作量统计</el-menu-item>
				</el-submenu>
				<el-submenu index="/data_statistics/telephone_traffic_statement">
					<template slot="title"><i class="el-icon-document"></i><span>话务报表</span></template>
					<el-menu-item index="/data_statistics/telephone_traffic_statement/call_capture_rate_statistics">接通率统计</el-menu-item>
					<el-menu-item index="/data_statistics/telephone_traffic_statement/outbound_call_statistics">外呼统计</el-menu-item>
					<el-menu-item index="/data_statistics/telephone_traffic_statement/ivr_statistics">IVR统计</el-menu-item>
				</el-submenu>
			</el-submenu>
			<el-submenu index="/6">
				<template slot="title"><i class="el-icon-goods"></i><span>数据分析</span></template>
				<el-submenu index="/7">
					<template slot="title"><i class="el-icon-document"></i><span>重复投诉</span></template>
					<el-menu-item index="/71">来电号码重复</el-menu-item>
					<el-menu-item index="/72">来电诉求重复</el-menu-item>
					<el-menu-item index="/73">诉求内容</el-menu-item>
				</el-submenu>
				<el-submenu index="/8">
					<template slot="title"><i class="el-icon-document"></i><span>工单分析</span></template>
					<el-menu-item index="/81">退回分析</el-menu-item>
					<el-menu-item index="/82">延期分析</el-menu-item>
					<el-menu-item index="/83">重办分析</el-menu-item>
				</el-submenu>
				<el-submenu index="/9">
					<template slot="title"><i class="el-icon-document"></i><span>办理不满意分析</span></template>
					<el-menu-item index="/91">来电不满意分析</el-menu-item>
					<el-menu-item index="/92">业务类别不满意分析</el-menu-item>
					<el-menu-item index="/93">事件类型不满意分析</el-menu-item>
				</el-submenu>
				<el-submenu index="/10">
					<template slot="title"><i class="el-icon-document"></i><span>三涉分析</span></template>
					<el-menu-item index="/101">涉众分析</el-menu-item>
					<el-menu-item index="/102">涉访分析</el-menu-item>
					<el-menu-item index="/103">涉稳分析</el-menu-item>
				</el-submenu>
				<el-submenu index="/11">
					<template slot="title"><i class="el-icon-document"></i><span>热词分析</span></template>
					<el-menu-item index="/111">热词分析</el-menu-item>
				</el-submenu>
			</el-submenu>
			<el-submenu index="/12">
				<template slot="title"><i class="el-icon-document"></i><span>画像</span></template>
				<el-menu-item index="/121">个人画像</el-menu-item>
				<el-menu-item index="/122">企业画像</el-menu-item>
				<el-menu-item index="/123">区域画像</el-menu-item>
			</el-submenu>
			<el-submenu index="/13">
				<template slot="title"><i class="el-icon-pie-chart"></i><span>自定义报表</span></template>
				<el-menu-item index="/131"><i class="el-icon-setting"></i><span>报表设置</span></el-menu-item>
				<el-menu-item index="/132"><i class="el-icon-setting"></i><span>权限设置</span></el-menu-item>
				<el-menu-item index="/133"><i class="el-icon-search"></i><span>报表查询</span></el-menu-item>
			</el-submenu>
			<el-submenu index="/14">
				<template slot="title"><i class="el-icon-monitor"></i><span>大屏数据展示</span></template>
				<el-menu-item index="/141"><i class="el-icon-data-board"></i><span>工单大屏</span></el-menu-item>
				<el-menu-item index="/142"><i class="el-icon-data-board"></i><span>话务大屏</span></el-menu-item>
				<el-menu-item index="/143"><i class="el-icon-data-board"></i><span>融合大屏</span></el-menu-item>
			</el-submenu>
		</el-menu>
-->
		<el-menu
				router
				:default-active="active"
				class="el-menu-vertical-demo"
				:collapse="isCollapse"
				:unique-opened="true"
				:collapse-transition="false"
				@select="selectMenu"
		>
<!--			<el-menu-item index="/">-->
<!--				<i class="el-icon-monitor"></i><span slot="title">工作台</span>-->
<!--			</el-menu-item>-->
			
			<template v-for="item in menuData">
				<template v-if="!item.children">
					<el-menu-item v-if="item.meta.isBlank" @click="toTargetBlank(item)" >
						<i :class="[item.meta.icon ? item.meta.icon : 'el-icon-goods']"></i><span>{{ item.meta.title }}</span>
					</el-menu-item>
					<el-menu-item v-else :index="'/'+item.path" >
						<i :class="[item.meta.icon ? item.meta.icon : 'el-icon-goods']"></i><span>{{ item.meta.title }}</span>
					</el-menu-item>
				</template>
				<el-submenu v-else :index="'/'+item.path" >
					<template slot="title"><i :class="[item.meta.icon ? item.meta.icon : 'el-icon-goods']"></i><span>{{ item.meta.title }}</span></template>
					<template v-for="item2 in item.children">
						
						<template v-if="!item2.children">
							<el-menu-item v-if="item2.meta.isBlank" @click="toTargetBlank(item2)" >
								<i :class="[item2.meta.icon ? item2.meta.icon : 'el-icon-goods']"></i><span>{{ item2.meta.title }}</span>
							</el-menu-item>
							<el-menu-item v-else :index="'/'+item.path+'/'+item2.path" >
								<i :class="[item2.meta.icon ? item2.meta.icon : 'el-icon-goods']"></i><span>{{ item2.meta.title }}</span>
							</el-menu-item>
						</template>
						<el-submenu v-if="item2.children" :index="'/'+item.path+'/'+item2.path">
							<template slot="title"><i :class="[item2.meta.icon ? item2.meta.icon : 'el-icon-goods']"></i><span>{{ item2.meta.title }}</span></template>
							<template v-for="item3 in item2.children">
								
								<template v-if="!item3.children">
									<el-menu-item v-if="item3.meta.isBlank" @click="toTargetBlank(item)" >
										<i :class="[item3.meta.icon ? item3.meta.icon : 'el-icon-goods']"></i><span>{{ item3.meta.title }}</span>
									</el-menu-item>
									<el-menu-item v-else :index="'/'+item.path+'/'+item2.path+'/'+item3.path" >
										<i :class="[item3.meta.icon ? item3.meta.icon : 'el-icon-goods']"></i><span>{{ item3.meta.title }}</span>
									</el-menu-item>
								</template>
								
								<el-submenu v-if="item3.children" :index="'/'+item.path+'/'+item2.path+'/'+item3.path" :title="item.path+'/'+item2.path+'/'+item3.path">
									<template slot="title"><i :class="[item3.meta.icon ? item3.meta.icon : 'el-icon-goods']"></i><span>{{ item3.meta.title }}</span></template>
									<template v-for="item4 in item3.children">
										
										<template v-if="!item4.children">
											<el-menu-item v-if="item4.meta.isBlank" @click="toTargetBlank(item)" >
												<i :class="[item4.meta.icon ? item4.meta.icon : 'el-icon-goods']"></i><span>{{ item4.meta.title }}</span>
											</el-menu-item>
											<el-menu-item v-else :index="'/'+item.path+'/'+item2.path+'/'+item3.path+'/'+item4.path" >
												<i :class="[item4.meta.icon ? item4.meta.icon : 'el-icon-goods']"></i><span>{{ item4.meta.title }}</span>
											</el-menu-item>
										</template>
										
										<el-submenu v-if="item4.children" :index="'/'+item.path+'/'+item2.path+'/'+item3.path+'/'+item4.path">
											<template slot="title"><i :class="[item4.meta.icon ? item4.meta.icon : 'el-icon-goods']"></i><span>{{ item4.meta.title }}</span></template>
											<el-menu-item index="/">上限了...</el-menu-item>
										</el-submenu>
									</template>
								</el-submenu>
							</template>
						</el-submenu>
					</template>
				</el-submenu>
			</template>
			
		</el-menu>
	</div>
</template>

<script>
export default {
	name: "menuX",
	props: {
		isCollapse: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			active:'/',
			menuData: this.$router.options.routes[0].children,
		};
	},
	created() {
		// console.log('menu:',this.$route);
		//首次处理路由
		if(this.$route.meta.activeMenu){
			this.active = this.$route.meta.activeMenu;
		}else{
			this.active = this.$route.path;
		}
		//console.log(this.$router.options.routes[0].children)
	},
	watch: {
		//监听路由变化，切换页面组件
		$route(to,from){
			//console.log('MenuToFrom:',to,from);
			//判断是否含有 meta:{activeMenu:'/product/list'}
			let {meta,path} = to;
			//console.log('Meta:',meta);
			//console.log('Path:',path);
			if(meta.activeMenu){
				this.active = meta.activeMenu;
			}else{
				this.active = path;
			}
		}
	},
	methods: {
		selectMenu(index, indexPath) {
			// console.log('selectMenu:',index, indexPath)
			console.log(this.$route)
			/*
			if (indexPath[0] === "#train") {
				//选择了确认,到测验页中去组卷,这里只要新窗口打开相应的测验页
				let routeData = this.$router.resolve({
					//去掉开头防使用index做router的#符
					path: "/chart" .replace("#", "")
				});
				window.open(routeData.href, "_blank");
			}
			*/
		},
		toTargetBlank(item){
			console.log(item)
			if(item.redirect){
				window.open(item.redirect, "_blank");
			}else{
				window.open(item.path, "_blank");
			}
		}
	}
}
</script>

<style scoped>
/*
.el-menu {
	border-right: none;
	.is-active {
		//background-color: rgba(0,0,0,0.3) !important;
	}
}
*/


.menuTitle { padding: 15px; font-weight: lighter; text-align: center; color: rgba(255,255,255,0.3); background-color: rgba(0,0,0,0.2); border-bottom: 1px solid rgba(255,255,255,0.05) !important; }


/*-----------------------------------------------------------------*/

.el-menu-vertical-demo:not(.el-menu--collapse) { width: 250px; }

.el-menu-vertical-demo:not(.el-menu--collapse) { background-color: #3C3F41; border-right: none; }
/*修改子菜单的背景色*/
>>> .el-menu-vertical-demo:not(.el-menu--collapse) .el-menu-item { color: #FFFFFF; background-color: rgba(0,0,0,0.1); height: 40px; line-height: 40px; }

/*鼠标悬浮时，子菜单的样式*/
.el-menu-vertical-demo:not(.el-menu--collapse) .el-menu-item:hover { outline: 0 !important; color: #333333 !important; background-color: rgba(255, 255, 255, 1) !important; }
/*子菜单 选中的样试*/
.el-menu-vertical-demo:not(.el-menu--collapse) .el-menu-item.is-active { color: #FFFFFF !important;	background: #3F499E !important; }

.el-menu-vertical-demo:not(.el-menu--collapse) .el-submenu.is-opened,
.el-menu-vertical-demo:not(.el-menu--collapse) .el-submenu.is-active { background-color: rgba(0,0,0,0.1) !important; }
>>> .el-menu-vertical-demo:not(.el-menu--collapse) .el-submenu__title { color: #FFFFFF; background-color: rgba(0,0,0,0.1) !important; height: 40px; line-height: 40px; }
>>> .el-menu-vertical-demo:not(.el-menu--collapse) .el-submenu .el-menu { background-color: rgba(0,0,0,0.1) !important; }
>>> .el-menu-vertical-demo:not(.el-menu--collapse) .el-submenu.is-active > .el-submenu__title { background-color: rgba(0, 0, 0, 0.3) !important; } /*当前选择父级高亮标记*/

/*鼠标悬浮时，主菜单的样式*/
>>> .el-menu-vertical-demo:not(.el-menu--collapse) .el-submenu__title:focus,
>>> .el-menu-vertical-demo:not(.el-menu--collapse) .el-submenu__title:hover { outline: 0 !important; color: #333333 !important; background-color: rgba(255, 255, 255, 1) !important; }

/*当前选择的父级高亮标记 鼠标悬浮效果*/
>>> .el-menu-vertical-demo:not(.el-menu--collapse) .is-active > .el-submenu__title:focus,
>>> .el-menu-vertical-demo:not(.el-menu--collapse) .is-active > .el-submenu__title:hover { outline: 0 !important; color: #333333 !important; background-color: rgba(255, 255, 255, 1) !important; }

/*层级缩进左边虚线*/
>>> .el-menu-vertical-demo:not(.el-menu--collapse) .el-menu { position: relative; }
>>> .el-menu-vertical-demo:not(.el-menu--collapse) .el-menu:before { content: ''; display: block; width: 10px; height: calc(100% - 40px); position: absolute; top: 20px; left: 20px; border-top: 1px dashed rgba(255,255,255,0.3);; border-right: 1px dashed rgba(255,255,255,0.3); }
>>> .el-menu-vertical-demo:not(.el-menu--collapse) .el-menu .el-menu:before { left: 40px; }
>>> .el-menu-vertical-demo:not(.el-menu--collapse) .el-menu .el-menu .el-menu:before { left: 60px; }
>>> .el-menu-vertical-demo:not(.el-menu--collapse) .el-menu .el-menu .el-menu .el-menu:before { left: 80px; }

/*文字超出处理*/
>>> .el-menu-vertical-demo:not(.el-menu--collapse) .el-menu-item { display: flex; align-items: center; }
>>> .el-menu-vertical-demo:not(.el-menu--collapse) .el-menu-item > span { display: block; overflow: hidden; white-space: nowrap; text-overflow: ellipsis; }
>>> .el-menu-vertical-demo:not(.el-menu--collapse) .el-submenu__title { display: flex; align-items: center; }
>>> .el-menu-vertical-demo:not(.el-menu--collapse) .el-submenu__title > span { flex-grow: 1; overflow: hidden; white-space: nowrap; text-overflow: ellipsis; }

/*-----------------------------------------------------------------*/


/*折叠起来的效果调整*/
.el-menu-vertical-demo.el-menu--collapse.el-menu { background-color: transparent; border-right: none; } /*移除背景色*/
.el-menu-vertical-demo.el-menu--collapse.el-menu .el-submenu { position: relative; }
.el-menu-vertical-demo.el-menu--collapse.el-menu .el-submenu:after { content: ''; position: absolute; right: 1px; top: 50%; display: block; width: 16px; height: 1px; background-color: rgba(255,255,255,0.1); }
</style>

<style>

.el-menu-vertical-demo-popper { margin-left: 3px; margin-top: -2px; }
.el-menu-vertical-demo-popper .el-menu--vertical { margin-left: 12px; margin-top: -15px; }
.el-menu-vertical-demo-popper .el-menu { border-radius: 3px; outline: 1px solid rgba(55,57,75, 0.2); } /*rgba(0,0,0,0.025)*/
.el-menu-vertical-demo-popper .el-menu:before { content: ""; display: block; width: 0px; height: 0px; border: 6px solid transparent; border-right-color: rgba(55,57,75, 0.9); position: absolute; left: -10px; top: 25px; }
.el-menu-vertical-demo-popper .el-menu--popup { padding: 15px 10px; min-width: 50px !important; }
.el-menu-vertical-demo-popper .el-menu--popup-right-start  { margin: 0 2px; }
.el-menu-vertical-demo-popper .el-submenu .el-menu-item { min-width: 50px !important; }
.el-menu-vertical-demo-popper .el-menu-item,
.el-menu-vertical-demo-popper .el-submenu__title {
	font-size: 14px; border-bottom: 1px solid rgba(255,255,255,0.05); height: 32px; line-height: 32px; border-radius: 3px; padding-left: 10px !important;
	padding-right: 30px;
}
.el-menu-vertical-demo-popper .el-menu-item i,
.el-menu-vertical-demo-popper .el-submenu__title i { font-size: 14px; color: #666666; }
.el-menu-vertical-demo-popper .el-menu-item .el-submenu__icon-arrow,
.el-menu-vertical-demo-popper .el-submenu__title .el-submenu__icon-arrow { font-size: 12px; right: 10px;    margin-top: -5px; }
.el-menu-vertical-demo-popper .el-menu-item:hover,
.el-menu-vertical-demo-popper .el-submenu__title:hover { background-color: rgba(0, 0, 0, 0.05); }
.el-menu-vertical-demo-popper .el-menu-item.is-active { background-color: rgba(55,57,75,1); color: #FFFFFF; }
.el-menu-vertical-demo-popper .el-submenu .el-submenu__title { border-bottom-color: rgba(0,0,0,0.05); }
</style>