/**
 * 路由权限的处理
 */

import router from './index'
import store from  '../store/index'

router.beforeEach((to, from, next) => {
	//判断当前的TOKEN是否存在
	if(!store.state.UserToken){
		//未登录 (页面是否需要登录)
		if(to.matched.length > 0 && !to.matched.some(record => record.meta.requiresAuth)){
			next();
		}else{
			next({
				path:'/login'
			});
		}
	}else{
		//已登录 处理路由的访问权限
		next();
	}
})