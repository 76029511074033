//vue-axios网络请求封装

import axios from 'axios'
import qs from 'querystring' //qs 用于 AXIOS POST 的参数转换，因为默认为字符串，而后台需要的是对象，会造成后台接收不到参数。
import { get_token, remove_token } from '@/utils/auth'
import router from '@/router'
import { Message } from 'element-ui';
import { get } from 'jquery';


// 创建axios实例 instance
const request = axios.create({
    // baseURL: 'http://192.168.31.7:8028', // 会在发送请求时，自动在url前添加该url
    // baseURL: 'http://192.169.0.207:8028', //唐山
    baseURL: 'http://10.15.26.45:8028', //武汉
    
    // baseURL: 'http://192.168.31.7:8028', //大数据分析
    timeout: 5000
})

// 请求拦截器
// 所有网络请求都会先通过这个interceptor请求拦截器进行预处理
// 例如：可以在这里添加token，或者在请求头中携带token
request.interceptors.request.use(
    config => {
        // 在发送请求之前做些什么
        config.headers['Content-Type'] = 'application/json';
        // 除了登陆接口,带上token
        if (config.url !== "/api/user/login") {
            var token = get_token("token")
            if (token === '' || token === null || token === undefined) {
                // 跳转到首页
                router.push("/login");
                return config;
            } else {
                config.headers['token'] = token
            }

        }

        // config.headers.token = 'TOKEN...'; //不能设置中文，会报错
        //请求前开启加载进度条
        return config;
    },
    error => {
        //console.log("%c全局'请求拦截'Error...", "color:#FC3C49;")
        //console.log(error);
        return Promise.reject(error);
    }
)

// 响应拦截器
// 在请求结束后，对响应做点什么
// 例如：对响应数据做点什么. 如根据服务器的返回状态码做相应的处理
// 404，401，500，200，301 等等...
request.interceptors.response.use(
    response => {
        // 对响应数据做点什么
        if (response.status === 200) {
            if(response.data.code==='401'){
                // 跳转到首页
                router.push("/login");
            }
            return response.data;
        }
        
        Message({
            message: response.description,
            type: "error",
        });
    },
    error => {
        if (error.code === 'ECONNABORTED' && error.message.includes('timeout')) {
            // 请求超时
            Message({
                message: "请求超时，请稍后再试",
                type: "error"
            });
            return Promise.reject(new Error('请求超时，请稍后再试'));
        }
        const { response } = error;
        // closeLoading();
        if (response) {
            Message({
                message: response.message,
                type: "error",
            });
            return Promise.reject(response.message);
        }
        Message({
            message: "网络连接异常,请稍后再试!",
            type: "error",
        });
        return Promise.reject(error);
    }
)

//对外暴露
export default request;

/*
// GET请求
export function get(url, params) {
    return request.get(url, { params });
}

// POST请求
export function post(url, data) {
    return request.post(url, qs.stringify(data));
}

// DELETE请求
export function del(url, params) {
    return request.delete(url);
}

// PUT请求
export function put(url, data) {
    return axios.put(url, data);
}
*/
