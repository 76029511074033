<template>
	<div class="tagsNav">
		<el-tag size="small" closable
		        v-for="item in tagsNav"
		        :key="item.path"
		        :effect="item.path==$route.path ? 'dark' : 'plain'"
		        @click="goTo(item.path)"
		        @close="close(item)"
		>{{ item.title }}</el-tag>
		<!--
		<el-tag size="small" closable>Tags2</el-tag>
		<el-tag size="small" closable>Tags3</el-tag>
		-->
	</div>
</template>

<script>
export default {
	data(){
		return {
			tagsNav: [
				{
					title:"首页",
					path:"/home",
					isActive:true
				},
			]
		}
	},
	methods:{
		goTo(path){
			if(path === this.$route.path){ //跳转的页面是当前页面就终止
				return;
			}
			this.$router.push(path); // 跳转路由
		},
		close(itemX){
			// 关闭一个标签，遍历删除高亮哪个，并高亮最后一个
			// this.$route.path 当前路由
			let deleteIndex = 0; //要删除的标签的索引
			this.tagsNav.forEach((item,index) => {
				if(itemX.path === item.path){
					deleteIndex = index;
				}
			})
			if(deleteIndex>0){
				this.tagsNav.splice(deleteIndex,1);
			}
			//高亮数组最后一个
			if(this.$route.path !== this.tagsNav[this.tagsNav.length-1].path){
				this.$router.push(this.tagsNav[this.tagsNav.length-1].path).catch(err=>{
					console.log("报错：",err)
				});
			}
		}
	},
	watch:{
		$route:{
			immediate: true, //立即监听
			handler:function(to,from) {
				//console.log(to,from);
				let that = this
				let isFlag = true;
				this.tagsNav.forEach(item => {
					if (item.path === to.path) {
						item.isActive = true
						isFlag = false
					} else {
						item.isActive = false
					}
				})
				if(isFlag){
					that.tagsNav.push({
						title: to.meta.title ? to.meta.title : to.name,
						path: to.path,
						isActive:true
					})
				}
			}
		}
	}
}
</script>

<style lang="scss" scoped>
.tagsNav { padding: 5px 10px; display: flex; align-items: center; flex-wrap: nowrap; }
.tagsNav .el-tag { margin-right: 3px; cursor: pointer; }
</style>

