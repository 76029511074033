
//公共请求方法
// import { request, get, post } from '@/api/request';
import request from '@/api/request';
import qs from 'querystring' //qs 用于 AXIOS POST 的参数转换，因为默认为字符串，而后台需要的是对象，会造成后台接收不到参数。


const httpServer = {
	//登录接口 (username,password)
	login: function (data) {
		return request({
			url: '/api/user/login',
			method: 'post', //请求方式
			// data:qs.stringify(data) //请求参数
			data: data
		});
	},
	//退出登录(token)
	logout: function () {
		return request({
			url: '/api/user/logout',
			method: 'get',//请求方式
		})
	},
	//读取热搜列表 (act,page,pageSize,name,date)
	getWeiboHotSearchList: function (data) {
		console.log('读取热搜列表...');
		return request({
			url: '/api/api_20240314.php',
			method: 'post', //请求方式
			data: qs.stringify(data) //请求参数
		});
	},
	//读取话题词热搜数据
	getWeiboHotSearchTopic: function (data) {
		console.log('读取话题词热搜数据...');
		return request({
			url: '/api/api_20240314.php',
			method: 'post', //请求方式
			data: qs.stringify(data) //请求参数
		});
	},
	//读取指定时间热搜排名
	getWeiboHotSearchTimeRank: function (data) {
		console.log('读取指定时间热搜排名...');
		return request({
			url: '/api/api_20240314.php',
			method: 'post', //请求方式
			data: qs.stringify(data) //请求参数
		});
	}
}

export default httpServer;